<template>
  <div class="text-left">
    <div class="pkmn-data section-spacing">
      <v-row
        dense
        v-for="row in info"
        :key="row.label"
        :class="{ 'section-spacing': row.newSection }"
      >
        <v-col cols="12" sm="4">
          <span class="data__label">{{ row.label }}</span>
        </v-col>

        <v-col cols="12" sm="7" offset-sm="1">
          <span class="data__text">{{ row.text }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataUserInfo',
  props: ['data'],
  computed: {
    info() {
      if (!this.data) {
        return []
      }

      const info = this.data

      const result = [
        {
          label: this.$t('g_name'),
          text: `${info.first_name || ''} ${info.last_name || ''}`
        },
        {
          label: this.$t('g_email'),
          text: info.email
        }
      ]

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.data__label {
  @include text-label;
}

.data__text {
  @include text-input-filled;
  word-break: break-all;
}

.pkmn-button {
  margin-top: 32px;
}
</style>

<template>
  <div class="text-left">
    <div class="pkmn-data section-spacing">
      <v-row
        dense
        v-for="row in info"
        :key="row.label"
        :class="{ 'section-spacing': row.newSection }"
      >
        <v-col cols="12" sm="4">
          <span class="data__label">{{ row.label }}</span>
        </v-col>

        <v-col cols="12" sm="7" offset-sm="1">
          <span class="data__text">{{ row.text }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataCompanyInfo',
  props: ['data'],
  computed: {
    info() {
      if (!this.data) {
        return []
      }

      const info = this.data

      let result = [
        {
          label: this.$t('settings_company_name'),
          text: info.name
        },
        {
          label: this.$t('settings_company_business_id'),
          text: info.business_id
        },
        {
          label: this.$t('g_address'),
          text: info.address,
          newSection: true
        },
        {
          label: this.$t('g_city'),
          text: info.city
        },
        {
          label: this.$t('g_postal_code'),
          text: info.post_code
        }
      ]

      if (info.country_name && info.country_name.toLowerCase() === 'italy') {
        const italianInfo = [
          {
            label: this.$t('italian_fiscal_code'),
            text: info.fiscal_code,
            newSection: true
          },
          {
            label: this.$t('italian_vat_code'),
            text: info.vat_code
          }
        ]
        result = result.concat(italianInfo)
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.data__label {
  @include text-label;
}

.data__text {
  @include text-input-filled;
}

.pkmn-button {
  margin-top: 32px;
}
</style>

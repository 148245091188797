<template>
  <div class="text-left">
    <v-form
      ref="form"
      class="pkmn-form"
      @keyup.native.enter.prevent="submitForm"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="formData.name"
            class="pkmn-input"
            :label="$t('g_name')"
            :rules="[rules.required]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="formData.email"
            class="pkmn-input"
            :label="$t('g_email')"
            :rules="[rules.required, rules.email]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense v-if="errorMessage">
        <v-col cols="12" class="text-right">
          <p class="pkmn-form__error" v-html="errorMessage"></p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import api from '@/helpers/api'
import userHelpers from '@/helpers/user'
import rules from '@/helpers/validation'

export default {
  name: 'FormUserInfo',
  props: ['data'],
  data() {
    return {
      formData: {
        name: '',
        email: ''
      },
      originalData: {},
      rules: {
        ...rules
      },
      errorMessage: null
    }
  },
  methods: {
    async submitForm() {
      this.errorMessage = null

      if (!this.$refs.form.validate()) {
        return
      }

      const apiSettings = {
        method: 'put',
        service: 'users',
        url: `v1/users/${this.data.id}`,
        data: this.prepareSubmissionData(this.formData, this.originalData)
      }

      try {
        await api.promise(apiSettings)
        await userHelpers.fetchUserInfo()
        this.$emit('submit')
      } catch (error) {
        if (error[0].field === 'email') {
          this.$store.dispatch(
            'setSystemMessage',
            this.$t('account_email_exist')
          )
        } else {
          this.$store.dispatch('setSystemMessage', error)
        }
      }
    },
    /**
     * This method remove the field from submission if it is unchanged
     */
    prepareSubmissionData(data, originalData) {
      const result = {}

      Object.keys(data).forEach((field) => {
        // skip processing if the field is unchanged
        if (data[field] === originalData[field]) {
          return
        }

        switch (field) {
          case 'name':
            result.first_name = data.name
            result.last_name = ' '
            break
          default:
            result[field] = data[field]
            break
        }
      })

      return result
    },
    prefillData() {
      if (this.data) {
        this.formData.name =
          `${this.data.first_name || ''} ${this.data.last_name || ''}`.trim()
        this.formData.email = this.data.email

        this.originalData = Object.assign({}, this.formData)
      }
    }
  },
  mounted() {
    this.prefillData()
  }
}
</script>
